@import url("https://fonts.googleapis.com/css2?family=Inter&display=swap");

:root {
  --figma-red: #ff3b00;
  --figma-pink: #ff7667;
  --figma-purple: #ae4dff;
  --figma-blue: #00beff;
  --figma-green: #00d47b;
}

.light-theme {
  --sec-bg-color: #eeeded;
  --sec-font-color: #000;
  --inv: invert(0%);
  --faded-text:#383838a8;
} 

.dark-theme {
  --sec-bg-color: #000000;
  --sec-font-color: #fff; 
  --faded-text: #b3b3b3a8;
}

.figmaEvent {
  font-family: "Inter", sans-serif;
  --bg-color: transparent;
  --font-color: var(--sec-font-color);
  transition: all 0.5s ease-in;
  background-color: transparent;
  color: var(--font-color);
}

/* GENERAL */

.figmaEvent section {
  padding: 3.6rem;
  overflow-x: hidden;
  /* background-color: var(--sec-bg-color); */
  /* min-height: 100vh;
  place-content: center; */
}

.figmaEvent section h2 {
  font-size: 3.6rem;
  text-align: center;
  font-weight: bold;
}

section h3 {
  font-size: 2rem;
  text-align: center;
  font-weight: bold;
}

video {
      position: fixed;
      width: 100%;
      height: 100%;
      object-fit: cover;
  }

@media screen and (max-width: 768px) {
  .figmaEvent section {
    padding-inline: 1rem;
  }
  video {
    /*     position: absolute; */
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
}

/* END GENERAL */

/* HEADER SECTION */


.figmaEvent .navB {
  position: fixed;
  top: 1%;
  right: 0.5%;
  --_g: #0000 calc(99% - var(--border)),
    var(--figma-red) calc(100% - var(--border)) 99%, #0000;
  --_h: #0000 calc(99% - var(--border)),
    var(--figma-purple) calc(100% - var(--border)) 99%, #0000;
  --_i: #0000 calc(99% - var(--border)),
    var(--figma-blue) calc(100% - var(--border)) 99%, #0000;
  --_j: #0000 calc(99% - var(--border)),
    var(--figma-green) calc(100% - var(--border)) 99%, #0000;
  display: none;
}

.figmaEvent .navB span {
  background-color: var(--font-color);
}

.navigation {
  height: 60px;
  width: 100%;
  display: flex;
  align-items: center;
  position: relative;
  padding: 0.5rem 0.4rem;
  background-color: #ffffff00;
}

.navigation-menu {
  margin-left: auto;
  animation: nav-appear 1s linear;
}

@keyframes nav-appear {
  0% {
    transform: translateY(-200px);
  }
  100% {
    transform: translateY(0);
  }
}

.navigation-menu ul {
  font-size: 1.3rem;
  display: flex;
  padding: 0.5rem;
  background-color: var(--sec-bg-color);
  border: 4px solid var(--figma-purple);
  border-radius: 0.5rem;
  margin-top: 1rem;
}

.navigation-menu li {
  list-style-type: none;
  margin: 0 1rem;
  font-weight: bold;
  transition: all 0.3s ease-in-out;
}

.navigation-menu li a {
  text-decoration: none;
  display: block;
  width: 100%;
}

.navigation-menu li:nth-child(1):hover {
  color: var(--figma-red);
}
.navigation-menu li:nth-child(2):hover {
  color: var(--figma-pink);
}
.navigation-menu li:nth-child(3):hover {
  color: var(--figma-purple);
}
.navigation-menu li:nth-child(4):hover {
  color: var(--figma-blue);
}
.navigation-menu li:nth-child(5):hover {
  color: var(--figma-green);
}

@media screen and (max-width: 768px) {
  .figmaEvent header {
    background-color: transparent;
  }
  .figmaEvent .navB {
    display: block;
  }

  .navigation-menu.expanded ul {
    display: block;
  }

  .navigation-menu ul {
    display: none;
    position: absolute;
    /* top: 60px; */
    left: 0;
    flex-direction: column;
    width: 100%;
    height: calc(100vh - 77px);
    /* border-top: 1px solid black; */
    border: none;
    border-radius: 0;
  }
  .navigation-menu li {
    text-align: center;
    margin: 0;
  }
  .navigation-menu li a {
    /* color: black; */
    width: 100%;
    padding: 1.5rem 0;
  }
  .navigation-menu li:hover {
    background-color: #eee;
  }
}
/* END HEADER */

/* HERO SECTION */

.figmaEvent .event-hero {
  min-height: 90vh;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  overflow: hidden;
}

.figmaEvent .event-hero .hero-l {
  text-align: center;
  align-self: center;
  padding-inline: 3rem;
  padding-bottom: 3rem;
  /* margin-bottom: 5rem; */
}

.figmaEvent .event-hero .hero-l h1 {
  font-family: 'Product Sans';
}

.hero-l .hero-title {
  padding-block: 3rem;
}

.hero-title .gdsc {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.hero-title .gdsc p {
  font-family: cursive;
  font-style: italic;
}

.figmaEvent .event-hero .hero-l .reg-btn,
.event-contact .reg-btn, 
.Perks .reg-btn,
.Footer .reg-btn {
  display: inline-block;
  width: fit-content;
  margin: 0.8rem;
  padding: 0.5rem;
  font-size: 1.3rem;
  border-radius: 0.3rem;
  border: 2px solid var(--figma-purple);
  transition: all 0.3s ease-in-out;
}

.figmaEvent .event-hero .hero-l .reg-btn:hover,
.event-contact .reg-btn:hover, 
.Perks .reg-btn:hover,
.Footer .reg-btn:hover {
  color: #fff;
  background-color: var(--figma-purple);
}

.sub-img {
  width: 45%;
}

.event-hero .hero-img {
  transform: perspective(1000px) rotateX(4deg) rotateY(-16deg) rotateZ(4deg);
  display: flex;
  flex-wrap: wrap;
  transition: all 0.3s ease-in-out;
  animation: appear 1s linear, float 1.5s infinite linear alternate-reverse;
  overflow: hidden;
  max-height: 98%;
}

@media (hover: hover) {
  .event-hero .hero-img:hover {
    transform: rotateX(0) rotateY(0) rotateZ(0);
  }
}

.event-hero .hero-img .phone {
  width: 23%;
  aspect-ratio: 9/16;
  /* border: 2px solid white; */
  border-radius: 0.3rem;
  background-color: var(--figma-green);
  box-shadow: 1px 2px 8px 0 var(--sec-font-color);
  opacity: 1;
  background-image: url(../../Images/Phone\ UI.jpeg);
  background-position: center;
  margin: 0.5rem;
}

.event-hero .hero-img img {
  border-radius: 0.3rem;
}

.event-hero .hero-img .pc {
  width: 100%;
  aspect-ratio: 16/9;
  /* border: 2px solid white; */
  border-radius: 0.3rem;
  margin: 0.3rem;
  background-color: var(--figma-green);
  box-shadow: 1px 2px 8px 0 rgba(246, 245, 245, 0.786);
  opacity: 1;
  background-image: url(../../Images/Phone\ UI.jpeg);
  background-position: center;
}

@keyframes appear {
  0% {
      opacity: 0;
      translate: 50px 0 50px;
  }
  100% {
    opacity: 1;
    translate: 0 0 0;
  }
}
@keyframes appear1 {
  0% {
      opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes float {
  0% {
    translate: 0 0;
  }
  100% {
    translate: 0 7px;
  }
}

.animate {
  animation: appear1 2s linear, float 1.5s infinite linear alternate-reverse 1s;
}


@media screen and (max-width: 990px) {
  .figmaEvent .event-hero {
    grid-template-columns: repeat(1, 1fr);
    padding-block: 1rem;
  }

  .figmaEvent .event-hero .hero-l {
    order: 2;
    padding-inline: 1rem;
    margin-bottom: 4rem;
  }

  .hero-title .gdsc {
    flex-direction: column;
  }

  .figmaEvent .event-hero .hero-img {
    display: none;
  }
}

/* END HERO */

/* SPONSORS SECTION */

.sponsor-box {
  border: 2px solid var(--figma-red);
  box-shadow: 2px 2px 4px var(--figma-red);
  background-color: #eeeded;
  padding: 1rem;
  border-radius: 0.5rem;

}

.sponsors h3 {
  font-size: 2rem;
  text-align: center;
  font-weight: bold;
}

.sponsor-box p{
  font-family: 'Courier New', Courier, monospace;
  font-weight: bold;
  color: black;
}

.sponsors .sponsor-box1 {
  border-color: var(--figma-blue);
  box-shadow: 2px 2px 4px var(--figma-blue);
}

.sponsors .sponsor-box2 {
  border-color: var(--figma-pink);
  box-shadow: 2px 2px 4px var(--figma-pink);
} 

.sponsors .sponsor-box3, .sponsor-box1 {
  border-color: var(--figma-purple);
  box-shadow: 2px 2px 4px var(--figma-purple);

  
  height: 12rem;
  aspect-ratio: 1/1;
} 

/* END SPONSORS */

/* ABOUT SECTION */
.event-about, .speakers, .event-schedule, .event-organizers {
  margin-block: 2rem;
  margin-inline: 4rem;
  background-color: var(--sec-bg-color);
}

@media screen and (max-width : 768px) {
  .event-about, .speakers, .event-schedule, .event-organizers {
    margin-inline: 0.5rem;
  }
}

.event-about {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  border: 2px solid var(--figma-pink);
  border-radius: 0.4rem;
  /* margin-inline: 0.5rem; */
  /* background-color: #000; */
}

.event-about h2 {
  color: var(--figma-pink);
}

.abt-l,
.abt-r {
  place-self: center;
}

.abt-l {
  padding-inline: 2rem;
}

.abt-l h2 {
  font-size: 3.6rem;
}

.abt-r {
  text-align: justify;
}

.abt-r h3 {
  font-size: 2rem;
}

.abt-r p {
  color: var(--figma-pink);
}

.about-venue {
  padding: 0.5rem;

  display: flex;
  /* justify-content: center; */
  align-items: center;
  gap: 1rem;
}

.event-about .map{
  border: 4px solid var(--figma-pink);
  border-radius: 0.4rem;
}

.disclaimer {
  color: var(--faded-text);
  font-size: 0.9rem;
}

@media screen and (max-width: 990px) {
  .event-about {
    display: flex;
    flex-direction: column;
    padding-inline: 0.6rem;
  }

  .abt-r {
    padding: 0.4rem;
  }

  .about-venue {
    flex-direction: column;
  }

  .event-about .map{
    width: 250px;
    height: 250px;
  }
}

/* END ABOUT */

/* SPEAKERS SECTION */

.speakers {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  border: 2px solid var(--figma-purple);
  border-radius: 0.4rem;
  /* margin-inline: 0.5rem; */
  /* background-color: #000; */
  /* min-height: 100vh; */
}

.speakers h2 {
  color: var(--figma-purple);
}

.spkrs-l,
.spkrs-r {
  place-self: center;
}

.spkrs-l-mobile {
  display: none;
}

.spkrs-r h2 {
  font-size: 3.6rem;
}

.round {
  position: absolute;
  top: 1.5rem;
  /* right: 9.5rem; */
  aspect-ratio: 1/1;
  border: 2px solid var(--figma-purple);
  border-color: var(--figma-purple);
  border-radius: 50%;
  height: 120px;
  margin-inline: auto;
  padding: 2px;
}

/* .speakers .round {
  filter: blur(5px);
} */

@media screen and (max-width:375px) {
  .round {
    bottom: 3.3rem;
  }
}

.parent {
  top: 25vh; /* delete this for integration*/
  width: 350px;
  height: 300px;
  perspective: 1000px;
}

.figmaEvent .ecard {
  height: 100%;
  border-radius: 30px;
  background: linear-gradient(135deg, #c38dee 0%, #ae4dff 100%);
  transition: all 0.5s ease-in-out;
  transform-style: preserve-3d;
  box-shadow: rgba(5, 71, 17, 0) 40px 50px 25px -40px, rgba(5, 71, 17, 0.2) 0px 25px 25px -5px;
}

.glass {
  transform-style: preserve-3d;
  position: absolute;
  inset: 8px;
  border-radius: 27px;
  border-top-right-radius: 100%;
  background: linear-gradient(0deg, rgba(255, 255, 255, 0.349) 0%, rgba(255, 255, 255, 0.815) 100%);
  /* -webkit-backdrop-filter: blur(5px);
  backdrop-filter: blur(5px); */
  transform: translate3d(0px, 0px, 25px);
  border-left: 1px solid white;
  border-bottom: 1px solid white;
  transition: all 0.5s ease-in-out;
}

.content {
  padding: 100px 45px 0px 25px;
  transform: translate3d(0, 0, 26px);
}

.content .title {
  position: relative;
  top: 3rem;
  display: block;
  color: #052919;
  font-weight: 900;
  font-size: 20px;
}

.content .text {
  position: relative;
  top: 2rem;
  display: block;
  color: rgba(0, 0, 0, 0.765);
  font-size: 15px;
  margin-top: 20px;
  font-weight: bold;
  text-align: left !important;
}

.bottom {
  padding: 10px 12px;
  transform-style: preserve-3d;
  position: absolute;
  bottom: 20px;
  left: 20px;
  right: 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  transform: translate3d(0, 0, 26px);
}

.bottom .view-more {
  display: flex;
  align-items: center;
  width: 40%;
  justify-content: flex-end;
  transition: all 0.2s ease-in-out;
}

.bottom .view-more:hover {
  transform: translate3d(0, 0, 10px);
}

.bottom .view-more .view-more-button {
  background: none;
  border: none;
  color: #00c37b;
  font-weight: bolder;
  font-size: 12px;
}

.bottom .view-more .svg {
  fill: none;
  stroke: #00c37b;
  stroke-width: 3px;
  max-height: 15px;
}

.bottom .social-buttons-container {
  display: flex;
  gap: 10px;
  transform-style: preserve-3d;
}

.bottom .social-buttons-container .social-button {
  width: 30px;
  aspect-ratio: 1;
  padding: 5px;
  background: rgb(255, 255, 255);
  border-radius: 50%;
  border: none;
  display: grid;
  place-content: center;
  box-shadow: rgba(5, 71, 17, 0.5) 0px 7px 5px -5px;
}

.bottom .social-buttons-container .social-button:first-child {
  transition: transform 0.2s ease-in-out 0.4s, box-shadow 0.2s ease-in-out 0.4s;
}

.bottom .social-buttons-container .social-button:nth-child(2) {
  transition: transform 0.2s ease-in-out 0.6s, box-shadow 0.2s ease-in-out 0.6s;
}

.bottom .social-buttons-container .social-button:nth-child(3) {
  transition: transform 0.2s ease-in-out 0.8s, box-shadow 0.2s ease-in-out 0.8s;
}

.bottom .social-buttons-container .social-button .svg {
  width: 15px;
  fill: #00894d;
}
.bottom .social-buttons-container .social-button img {
  width: 15px;
  fill: #00894d;
}

.bottom .social-buttons-container .social-button:hover {
  background: black;
}

.bottom .social-buttons-container .social-button:hover .svg {
  fill: white;
}
.bottom .social-buttons-container .social-button:hover img {
  fill: white;
}

.bottom .social-buttons-container .social-button:active {
  background: rgb(255, 234, 0);
}

.bottom .social-buttons-container .social-button:active .svg {
  fill: black;
}
.bottom .social-buttons-container .social-button:active img {
  fill: black;
}

.figmaEvent .logo {
  position: absolute;
  right: 0;
  top: 0;
  transform-style: preserve-3d;
}

.logo .circle {
  display: block;
  position: absolute;
  aspect-ratio: 1;
  border-radius: 50%;
  top: 0;
  right: 0;
  box-shadow: rgba(100, 100, 111, 0.2) -10px 10px 20px 0px;
  -webkit-backdrop-filter: blur(5px);
  backdrop-filter: blur(5px);
  background: rgba(0, 249, 203, 0.2);
  transition: all 0.5s ease-in-out;
}

.logo .circle1 {
  width: 170px;
  transform: translate3d(0, 0, 20px);
  top: 8px;
  right: 8px;
}

.logo .circle2 {
  width: 140px;
  transform: translate3d(0, 0, 40px);
  top: 10px;
  right: 10px;
  -webkit-backdrop-filter: blur(1px);
  backdrop-filter: blur(1px);
  transition-delay: 0.4s;
}

.logo .circle3 {
  width: 110px;
  transform: translate3d(0, 0, 60px);
  top: 17px;
  right: 17px;
  transition-delay: 0.8s;
}

.logo .circle4 {
  width: 80px;
  transform: translate3d(0, 0, 80px);
  top: 23px;
  right: 23px;
  transition-delay: 1.2s;
}

.logo .circle5 {
  width: 50px;
  transform: translate3d(0, 0, 100px);
  top: 30px;
  right: 30px;
  display: grid;
  place-content: center;
  transition-delay: 1.6s;
}

.logo .circle5 img {
  width: 20px;
  fill: white;
}

.parent:hover .ecard .bottom .social-buttons-container .social-button {
  transform: translate3d(0, 0, 50px);
  box-shadow: rgba(5, 71, 17, 0.2) -5px 20px 10px 0px;
}

.parent:hover .ecard .logo .circle2 {
  transform: translate3d(0, 0, 60px);
}

.parent:hover .ecard .logo .circle3 {
  transform: translate3d(0, 0, 80px);
}

.parent:hover .ecard .logo .circle4 {
  transform: translate3d(0, 0, 100px);
}

.parent:hover .ecard .logo .circle5 {
  transform: translate3d(0, 0, 120px);
}

@media screen and (max-width: 990px) {
  .speakers {
    display: flex;
    flex-direction: column;
  }

  .spkrs-l {
    order: 2;
    margin-block: 2rem;
  }

  .spkrs-r {
    order: 1;
  }

  .spkrs-l-mobile {
    display: flex;
  }

  .spkrs-l-pc {
    display: none;
  }
}

.figmaEvent .carousel .slide img {
  border: 2px solid var(--figma-purple) !important;
}

/* END SPEAKERS */

/* ORGANIZERS SECTION */

.event-organizers {
  border: 2px solid var(--figma-blue);
  border-radius: 0.4rem;
  /* margin-inline: 0.5rem; */
  /* background-color: #000; */
}

.event-organizers h2, .event-organizers h3 {
  color: var(--figma-blue);
}

.event-organizers .card-container {
  margin-block: 2rem;
}

.figmaEvent .organizers .round {
  border-color: var(--figma-blue);
}

.figmaEvent .organizers .ecard {
  background: linear-gradient(135deg, #82c7de 0%, var(--figma-blue) 100%);
}

/* END ORGANIZERS */

/* SCHEDULE SECTION */

.event-schedule {
  border: 2px solid var(--figma-green);
  border-radius: 0.4rem;
  /* margin-inline: 0.5rem; */
  /* background-color: #000; */
}

.event-schedule h2 {
  color: var(--figma-green);
}

.timeline {
  padding: 2rem;
}

.timeline .flex {
  margin: 0.4rem;
}

.timeline .flex:nth-child(even) {
  justify-content: end;
}

.timeline-item {
  height: fit-content;
  width: 25%;
  min-width: 300px;
  border: 4px solid var(--figma-purple);
  border-radius: 0.4rem;
  
  display: grid;
  grid-template-rows: repeat(2, auto)
}

.timeline-item::after {
  content: "";
  /* border: 2px solid black; */
  border-top: 4px solid var(--figma-red);
  border-radius: 0.4rem;
  width: 300%;
  height: 100px;
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: -2;
}

.timeline .flex:nth-child(odd) .timeline-item::after {
  border-right: 4px solid;
}
.timeline .flex:nth-child(even) .timeline-item::after {
  left: auto;
  right: 50%;
  border-left: 4px solid var(--figma-red);
}


.timeline .flex:nth-child(1) .timeline-item {
  border-color: var(--figma-red);
}
.timeline .flex:nth-child(1) .timeline-item::after {
  border-color: var(--figma-red);
}
.timeline .flex:nth-child(1) .time {
  background-color: var(--figma-red);
  color: #fff;
}

.timeline .flex:nth-child(2) .timeline-item, .timeline .flex:nth-child(7) .timeline-item {
  border-color: var(--figma-pink);
}
.timeline .flex:nth-child(2) .timeline-item::after, .timeline .flex:nth-child(7) .timeline-item::after {
  border-color: var(--figma-pink);
}
.timeline .flex:nth-child(2) .time, .timeline .flex:nth-child(7) .time {
  background-color: var(--figma-pink);
  color: #fff;
}

.timeline .flex:nth-child(3) .timeline-item, .timeline .flex:nth-child(8) .timeline-item {
  border-color: var(--figma-purple);
}
.timeline .flex:nth-child(3) .timeline-item::after, .timeline .flex:nth-child(8) .timeline-item::after {
  border-color: var(--figma-purple);
}
.timeline .flex:nth-child(3) .time, .timeline .flex:nth-child(8) .time {
  background-color: var(--figma-purple);
  color: #fff;
}

.timeline .flex:nth-child(4) .timeline-item, .timeline .flex:nth-child(9) .timeline-item {
  border-color: var(--figma-blue);
}
.timeline .flex:nth-child(4) .timeline-item::after, .timeline .flex:nth-child(9) .timeline-item::after {
  border-color: var(--figma-blue);
}
.timeline .flex:nth-child(4) .time, .timeline .flex:nth-child(9) .time {
  background-color: var(--figma-blue);
  color: #fff;
}
.timeline .flex:nth-child(5) .timeline-item, .timeline .flex:nth-child(10) .timeline-item {
  border-color: var(--figma-green);
}
.timeline .flex:nth-child(5) .timeline-item::after, .timeline .flex:nth-child(10) .timeline-item::after {
  border-color: var(--figma-green);
}
.timeline .flex:nth-child(5) .time, .timeline .flex:nth-child(10) .time {
  background-color: var(--figma-green);
  color: #fff;
}

.timeline .flex:nth-child(6) .timeline-item, .timeline .flex:nth-child(11) .timeline-item {
  border-color: var(--figma-red);
}
.timeline .flex:nth-child(6) .time, .timeline .flex:nth-child(11) .time {
  background-color: var(--figma-red);
  color: #fff;
}


@media screen and (max-width: 1280px) {
  .timeline-item::after {
      width: 200%;
  }
}

@media screen and (max-width: 1150px) {
  .timeline-item::after {
      width: 180%;
  }
}

.timeline .flex:last-child .timeline-item::after {
  background-color: transparent;
  border: none;
}

@media screen and (max-width: 990px) {
  .timeline .flex {
    justify-content: center;
    margin-block: 1rem;
  }
  .timeline .flex:nth-child(even) {
    justify-content: center;
  }
  .timeline-item::after {
      width: 4px;
      /* background-color: #000; */
      top: 80%;
      left: 50%;
      margin-inline: auto;
  }

}


.Perks .circle {
  background-color: var(--figma-red);
  border: 4px solid var(--sec-font-color);
  
}

.Perks .circle1 {
  background-color: var(--figma-pink);
  margin-inline: auto;
}
.Perks .circle2 {
  background-color: var(--figma-purple);
  margin-inline: auto;
}
.Perks .circle3 {
  background-color: var(--figma-blue);
  margin-inline: auto;
}
.Perks .circle4 {
  background-color: var(--figma-green);
  margin-inline: auto;
}

.figmaEvent .Footer {
  background-color: var(--sec-bg-color);
  color: var(--sec-font-color);
}

.figmaEvent .Footer .reg-btn {
  margin: 0;
  padding: 0.3rem;
  font-size: 1rem;
}

/* GLIMPSES */
.glimpse h2 {
  color: var(--figma-blue);
}

.photo-container {
  /* border: 2px solid white; */
  aspect-ratio: 16/9;
  width: 100%;
  margin-inline: auto;
  max-height: 90vh;

  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: repeat(1, 1fr);
  gap: 0.2rem;
}

.photo-container-1, .photo-container-2 {
  /* border: 2px solid white; */
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.photo-container-2 {
  grid-column-start: 2;
  grid-column-end: 4;
  width: 100%;

  display: grid;
  grid-template-columns: repeat(1, 1fr);
  grid-template-rows: repeat(2, 1fr);
}

.photo-container img {
  border-radius: 0.5rem;
  object-fit: cover;
  height: 100%;
  width: 100%;


}

.sub-container-2 .img-1 {
  position: relative;
}

/* .sub-container-2 .img-1::after {
  content: " ";
  position: absolute;
  background-color: #af4dff48;

  z-index: 1;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;

  border-radius: 0.5rem;
} */

@media screen and (max-width: 769px) {
  .photo-container {
    display: block;
    aspect-ratio: auto;
    max-height: none;
  }
  .photo-container-2 {
    display: block;
  }
}


/* LOADER */
.loader {
  background: var(--sec-bg-color);
	color: var(--sec-font-color);
	font: bold 1em/1.5 "Comfortaa", sans-serif;
	display: grid;
	place-items: center;
	align-content: center;
	height: 100vh;
	letter-spacing: 0.1em;
	text-transform: uppercase;
  --dur: 8s;
  transition: all 0.3s ease-in-out;
}

.hexagon {
	margin-bottom: 1.5em;
	overflow: hidden;
	position: relative;
	width: 15em;
	height: 15em;
}
.hexagon__group, .hexagon__sector, .hexagon__sector:before, .hexagon__sector:after {
	position: absolute;
}
.hexagon__group {
	width: 100%;
	height: 100%;
}
.hexagon__group:nth-child(2) .hexagon__sector,
.hexagon__group:nth-child(2) .hexagon__sector:before,
.hexagon__group:nth-child(2) .hexagon__sector:after {
	animation-delay: calc(var(--dur) * -1/6);
}
.hexagon__group:nth-child(3) .hexagon__sector,
.hexagon__group:nth-child(3) .hexagon__sector:before,
.hexagon__group:nth-child(3) .hexagon__sector:after {
	animation-delay: calc(var(--dur) * -2/6);
}
.hexagon__group:nth-child(4) .hexagon__sector,
.hexagon__group:nth-child(4) .hexagon__sector:before,
.hexagon__group:nth-child(4) .hexagon__sector:after {
	animation-delay: calc(var(--dur) * -3/6);
}
.hexagon__group:nth-child(5) .hexagon__sector,
.hexagon__group:nth-child(5) .hexagon__sector:before,
.hexagon__group:nth-child(5) .hexagon__sector:after {
	animation-delay: calc(var(--dur) * -4/6);
}
.hexagon__group:nth-child(6) .hexagon__sector,
.hexagon__group:nth-child(6) .hexagon__sector:before,
.hexagon__group:nth-child(6) .hexagon__sector:after {
	animation-delay: calc(var(--dur) * -5/6);
}
.hexagon__group:nth-child(odd) {
	transform: rotate(30deg);
}
.hexagon__sector, .hexagon__sector:before, .hexagon__sector:after {
	animation-duration: var(--dur);
	animation-iteration-count: infinite;
	animation-timing-function: linear;
	/*animation-play-state: paused;*/
	width: 0.2em;
	height: 0.2em;
}
.hexagon__sector {
	animation-name: moveOut1;
	top: calc(50% - 0.1em);
	left: calc(50% - 0.1em);
}
.hexagon__sector:nth-child(2) {
	animation-name: moveOut2;
}
.hexagon__sector:nth-child(3) {
	animation-name: moveOut3;
}
.hexagon__sector:nth-child(4) {
	animation-name: moveOut4;
}
.hexagon__sector:nth-child(5) {
	animation-name: moveOut5;
}
.hexagon__sector:nth-child(6) {
	animation-name: moveOut6;
}
.hexagon__sector:before, .hexagon__sector:after {
	animation-name: ripple;
	background-color: currentColor;
	border-radius: 0.1em;
	content: "";
	display: block;
	top: 0;
	left: 0;
	transform-origin: 0.1em 0.1em;
}
.hexagon__sector:before {
	transform: rotate(-30deg)
}
.hexagon__sector:after {
	transform: rotate(-150deg)
}

/* Animations */
@keyframes moveOut1 {
	from { transform: translateY(0) scale(0); }
	3% { transform: translateY(0.2em) scale(1); }
	97% { transform: translateY(7.3em) scale(1); }
	to { transform: translateY(7.5em) scale(0); }
}
@keyframes moveOut2 {
	from { transform: rotate(60deg) translateY(0) scale(0); }
	3% { transform: rotate(60deg) translateY(0.2em) scale(1); }
	97% { transform: rotate(60deg) translateY(7.3em) scale(1); }
	to { transform: rotate(60deg) translateY(7.5em) scale(0); }
}
@keyframes moveOut3 {
	from { transform: rotate(120deg) translateY(0) scale(0); }
	3% { transform: rotate(120deg) translateY(0.2em) scale(1); }
	97% { transform: rotate(120deg) translateY(7.3em) scale(1); }
	to { transform: rotate(120deg) translateY(7.5em) scale(0); }
}
@keyframes moveOut4 {
	from { transform: rotate(180deg) translateY(0) scale(0); }
	3% { transform: rotate(180deg) translateY(0.2em) scale(1); }
	97% { transform: rotate(180deg) translateY(7.3em) scale(1); }
	to { transform: rotate(180deg) translateY(7.5em) scale(0); }
}
@keyframes moveOut5 {
	from { transform: rotate(240deg) translateY(0) scale(0); }
	3% { transform: rotate(240deg) translateY(0.2em) scale(1); }
	97% { transform: rotate(240deg) translateY(7.3em) scale(1); }
	to { transform: rotate(240deg) translateY(7.5em) scale(0); }
}
@keyframes moveOut6 {
	from { transform: rotate(300deg) translateY(0) scale(0); }
	3% { transform: rotate(300deg) translateY(0.2em) scale(1); }
	97% { transform: rotate(300deg) translateY(7.3em) scale(1); }
	to { transform: rotate(300deg) translateY(7.5em) scale(0); }
}
@keyframes ripple {
	from, to { width: 0.2em; }
	33% { width: 2.4em; }
}